/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { NavLink } from 'react-router-dom';

import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import { checkPermission } from 'utils/commonUtils';
import { ROUTING_CONSTANTS } from 'constants/constants';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Customer from '../../../src/assets/images/Colour/Customer.png';
import CustomerActive from '../../../src/assets/images/White/Customer.png';
import Company from '../../../src/assets/images/Colour/Company.png';
import CompanyActive from '../../../src/assets/images/White/Company.png';
import reason from '../../../src/assets/images/Colour/Actions-and-reasons.png';
import reasonActive from '../../../src/assets/images/White/Action-&-Reasons.png';
import action from '../../../src/assets/images/Colour/Actions.png';
import actionActive from '../../../src/assets/images/White/Action.png';
import Roles from '../../../src/assets/images/Colour/Roles.png';
import RolesActive from '../../../src/assets/images/White/Roles.png';
import AssignUnassignPermission from '../../../src/assets/images/Colour/Assign-Unassign-Permissions.png';
import AssignUnassignPermissionActive from '../../../src/assets/images/White/Assign-Unassign-Permissions.png';
import ItemCatalogue from '../../../src/assets/images/Colour/Item-Catalogue.png';
import ItemCatalogueActive from '../../../src/assets/images/White/Item-Catalogue.png';
import OrgChartSetup from '../../../src/assets/images/Colour/Org-Chart-Setup.png';
import OrgChartSetupActive from '../../../src/assets/images/White/Org-Chart-Setup.png';
import PasswordRules from '../../../src/assets/images/Colour/Password-Rules.png';
import PasswordRulesActive from '../../../src/assets/images/White/Password-Rules.png';
import ModuleAccess from '../../../src/assets/images/Colour/Module-Access.png';
import ModuleAccessActive from '../../../src/assets/images/White/Module-Access.png';
import NotificationTemplate from '../../../src/assets/images/Colour/Notification-Template.png';
import NotificationTemplateActive from '../../../src/assets/images/White/Notification-Template.png';
import BallotTwoToneIcon from '@material-ui/icons/BallotTwoTone';

const AdminMenu = (props) => {
  const { user, view, modules } = props;
  const location = useLocation();

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);
  return (
    <>
      <div className="sidebar-header">
        <span>Setup Pages</span>
      </div>
      <ul>
        {checkPermission({
          permId: ROUTING_CONSTANTS.CUSTOMER_DASHBOARD.id,
          permissions: user.permissions,
          view: view,
          modules: modules
        }) && (
          <li>
            <NavLink
              activeClassName="active"
              onClick={toggleSidebarMobile}
              className="nav-link-simple"
              to={ROUTING_CONSTANTS.CUSTOMER_DASHBOARD.path}>
              <span className="sidebar-icon">
                <img
                  src={
                    location.pathname === '/customer'
                      ? CustomerActive
                      : Customer
                  }
                  alt="..."
                  style={{ marginRight: '10px' }}
                />
              </span>
              Customer
            </NavLink>
          </li>
        )}
        {checkPermission({
          permId: ROUTING_CONSTANTS.COMPANY_DASHBOARD.id,
          permissions: user.permissions,
          view: view,
          modules: modules
        }) && (
          <li>
            <NavLink
              activeClassName="active"
              onClick={toggleSidebarMobile}
              className="nav-link-simple"
              to={ROUTING_CONSTANTS.COMPANY_DASHBOARD.path}>
              <span className="sidebar-icon">
                <img
                  src={
                    location.pathname === '/company' ? CompanyActive : Company
                  }
                  alt="..."
                  style={{ marginRight: '10px' }}
                />
              </span>
              Company
            </NavLink>
          </li>
        )}
        {checkPermission({
          permId: ROUTING_CONSTANTS.ROLES_DASHBOARD.id,
          permissions: user.permissions,
          view: view,
          modules: modules
        }) && (
          <li>
            <NavLink
              activeClassName="active"
              onClick={toggleSidebarMobile}
              className="nav-link-simple"
              to={ROUTING_CONSTANTS.ROLES_DASHBOARD.path}>
              <span className="sidebar-icon">
                <img
                  src={location.pathname === '/roles' ? RolesActive : Roles}
                  alt="..."
                  style={{ marginRight: '10px' }}
                />
              </span>
              Roles
            </NavLink>
          </li>
        )}
        {checkPermission({
          permId: ROUTING_CONSTANTS.ASSIGN_UNASSIGN_PERMISSIONS.id,
          permissions: user.permissions,
          view: view,
          modules: modules
        }) && (
          <li>
            <NavLink
              activeClassName="active"
              onClick={toggleSidebarMobile}
              className="nav-link-simple"
              to={ROUTING_CONSTANTS.ASSIGN_UNASSIGN_PERMISSIONS.path}>
              <span className="sidebar-icon">
                <img
                  src={
                    location.pathname === '/assignedPermissions'
                      ? AssignUnassignPermissionActive
                      : AssignUnassignPermission
                  }
                  alt="..."
                  style={{ marginRight: '10px' }}
                />
              </span>
              Assign/Unassign Permissions
            </NavLink>
          </li>
        )}
        {checkPermission({
          permId: ROUTING_CONSTANTS.ACTIONS.id,
          permissions: user.permissions,
          view: view,
          modules: modules
        }) && (
          <li>
            <NavLink
              activeClassName="active"
              onClick={toggleSidebarMobile}
              className="nav-link-simple"
              to={ROUTING_CONSTANTS.ACTIONS.path}>
              <span className="sidebar-icon">
                <img
                  src={location.pathname === '/actions' ? actionActive : action}
                  alt="..."
                  style={{ marginRight: '10px' }}
                />
              </span>
              Actions
            </NavLink>
          </li>
        )}
        {checkPermission({
          permId: ROUTING_CONSTANTS.SEARCH_ACTION_REASON.id,
          permissions: user.permissions,
          view: view,
          modules: modules
        }) && (
          <li>
            <NavLink
              activeClassName="active"
              onClick={toggleSidebarMobile}
              className="nav-link-simple"
              to={ROUTING_CONSTANTS.SEARCH_ACTION_REASON.path}>
              <span className="sidebar-icon">
                <img
                  src={
                    location.pathname === '/actionsAndReasons'
                      ? reasonActive
                      : reason
                  }
                  alt="..."
                  style={{ marginRight: '10px' }}
                />
              </span>
              Actions & Reasons
            </NavLink>
          </li>
        )}
        {checkPermission({
          permId: ROUTING_CONSTANTS.SECTION_80G.id,
          permissions: user.permissions,
          view: view,
          modules: modules
        }) && (
          <li>
            <NavLink
              activeClassName="active"
              onClick={toggleSidebarMobile}
              className="nav-link-simple"
              to={ROUTING_CONSTANTS.SECTION_80G.path}>
              <span className="sidebar-icon">
                <img
                  src={
                    location.pathname === '/section80G' ? reasonActive : reason
                  }
                  alt="..."
                  style={{ marginRight: '10px' }}
                />
              </span>
              Section 80G Setup
            </NavLink>
          </li>
        )}
        {checkPermission({
          permId: ROUTING_CONSTANTS.ITEM_CATALOGUE_DASHBOARD.id,
          permissions: user.permissions,
          view: view,
          modules: modules
        }) && (
          <li>
            <NavLink
              activeClassName="active"
              onClick={toggleSidebarMobile}
              className="nav-link-simple"
              to={ROUTING_CONSTANTS.ITEM_CATALOGUE_DASHBOARD.path}>
              <span className="sidebar-icon">
                <img
                  src={
                    location.pathname === '/itemCatalogue'
                      ? ItemCatalogueActive
                      : ItemCatalogue
                  }
                  alt="..."
                  style={{ marginRight: '10px' }}
                />
              </span>
              Item Catalogue
            </NavLink>
          </li>
        )}
        {checkPermission({
          permId: ROUTING_CONSTANTS.MODULE_ACCESS.id,
          permissions: user.permissions,
          view: view,
          modules: modules
        }) && (
          <li>
            <NavLink
              activeClassName="active"
              onClick={toggleSidebarMobile}
              className="nav-link-simple"
              to={ROUTING_CONSTANTS.MODULE_ACCESS.path}>
              <span className="sidebar-icon">
                <img
                  src={
                    location.pathname === '/moduleAccess'
                      ? ModuleAccessActive
                      : ModuleAccess
                  }
                  alt="..."
                  style={{ marginRight: '10px' }}
                />
              </span>
              Module Access
            </NavLink>
          </li>
        )}
        {checkPermission({
          permId: ROUTING_CONSTANTS.PASSWORD_RULES.id,
          permissions: user.permissions,
          view: view,
          modules: modules
        }) && (
          <li>
            <NavLink
              activeClassName="active"
              onClick={toggleSidebarMobile}
              className="nav-link-simple"
              to={ROUTING_CONSTANTS.PASSWORD_RULES.path}>
              <span className="sidebar-icon">
                <img
                  src={
                    location.pathname === '/passwordRules'
                      ? PasswordRulesActive
                      : PasswordRules
                  }
                  alt="..."
                  style={{ marginRight: '10px' }}
                />
              </span>
              Password Rules
            </NavLink>
          </li>
        )}
        {checkPermission({
          permId: ROUTING_CONSTANTS.ORG_CHART_SETUP.id,
          permissions: user.permissions,
          view: view,
          modules: modules
        }) && (
          <li>
            <NavLink
              activeClassName="active"
              onClick={toggleSidebarMobile}
              className="nav-link-simple"
              to={ROUTING_CONSTANTS.ORG_CHART_SETUP.path}>
              <span className="sidebar-icon">
                <img
                  src={
                    location.pathname === '/orgChartSetup'
                      ? OrgChartSetupActive
                      : OrgChartSetup
                  }
                  alt="..."
                  style={{ marginRight: '10px' }}
                />
              </span>
              Org Chart Setup
            </NavLink>
          </li>
        )}
        {checkPermission({
          permId: ROUTING_CONSTANTS.NOTIFICATION_TEMPLATE.id,
          permissions: user.permissions,
          view: view,
          modules: modules
        }) && (
          <li>
            <NavLink
              activeClassName="active"
              onClick={toggleSidebarMobile}
              className="nav-link-simple"
              to={ROUTING_CONSTANTS.NOTIFICATION_TEMPLATE.path}>
              <span className="sidebar-icon">
                <img
                  src={
                    location.pathname === '/notificationTemplate'
                      ? NotificationTemplateActive
                      : NotificationTemplate
                  }
                  alt="..."
                  style={{ marginRight: '10px' }}
                />
              </span>
              Notification Template
            </NavLink>
          </li>
        )}
        {checkPermission({
          permId: ROUTING_CONSTANTS.SEARCH_IT_SECTION.id,
          permissions: user.permissions,
          view: view,
          modules: modules
        }) && (
          <li>
            <NavLink
              activeClassName="active"
              onClick={toggleSidebarMobile}
              className="nav-link-simple"
              to={ROUTING_CONSTANTS.SEARCH_IT_SECTION.path}>
              <span className="sidebar-icon">
                <BallotTwoToneIcon />
              </span>
              IT Section
            </NavLink>
          </li>
        )}
        {checkPermission({
          permId: ROUTING_CONSTANTS.INCOME_TAX_SLABS.id,
          permissions: user.permissions,
          view: view,
          modules: modules
        }) && (
          <li>
            <NavLink
              activeClassName="active"
              onClick={toggleSidebarMobile}
              className="nav-link-simple"
              to={ROUTING_CONSTANTS.INCOME_TAX_SLABS.path}>
              <span className="sidebar-icon">
                <BallotTwoToneIcon />
              </span>
              Income Tax Slabs
            </NavLink>
          </li>
        )}
      </ul>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.Auth.user,
  view: state.Auth.view,
  modules: state.Auth.modules
});

const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AdminMenu);

// export default AdminMenu;
