exports.ROUTING_CONSTANTS = {
  CUSTOMER_DASHBOARD: { path: '/customer', id: 1 },
  CREATE_CUSTOMER: { path: '/createCustomer', id: 2 },
  COMPANY_DASHBOARD: { path: '/company', id: 4 },
  CREATE_COMPANY: { path: '/createcompany', id: 5 },
  CREATE_ROLES: { path: '/createRoles', id: 7 },
  SEARCH_ACTION_REASON: { path: '/actionsAndReasons', id: 11 },
  CREATE_ACTION_REASON: { path: '/createActionReason', id: 13 },
  CREATE_ACTION: { path: '/createAction', id: 14 },
  CREATE_ITEM_CATALOGUE: { path: '/createItemCatalogue', id: 15 },
  HIRE_PROCESS: { path: '/hireProcess', id: 16 },
  ADD_NATIONAL_ID: { path: '/createNationalID', id: 17 },
  VIEW_NATIONAL_ID: { path: '/nationalIDs', id: 18 },
  ADD_ADDRESS: { path: '/addAddress', id: 19 },
  ADD_EMERGENCY_CONTACT: { path: '/addEmergencyContact', id: 20 },
  ADD_DEPENDENTS: { path: '/createDependants', id: 21 },
  ADD_WORK_EXPERIENCE: { path: '/addWorkExperience', id: 22 },
  ADD_PHONE_AND_EMAIL: { path: '/addPhoneAndEmailAddress', id: 23 },
  ADD_CERTIFICATE_OR_LICENSE: { path: '/addCertificateLicense', id: 24 },
  VIEW_EMPLOYEES: { path: '/employees', id: 25 },
  DEPARTMENT_DASHBOARD: { path: '/department', id: 26 },
  ADD_DEPARTMENT: { path: '/createDepartment', id: 27 },
  LOCATION_DASHBOARD: { path: '/locations', id: 29 },
  ADD_LOCATION: { path: '/createLocation', id: 30 },
  DESIGNATION_DASHBOARD: { path: '/designation', id: 32 },
  ADD_DESIGNATION: { path: '/createDesignation', id: 33 },
  ADD_EDUCATIONAL_DETAILS: { path: '/educationalDetails', id: 35 },
  USERID_SETUP: { path: '/createUserIdSetup', id: 36 },
  AUTO_NUMBERING_SETUP: { path: '/autoNumberingSetup', id: 37 },
  UPDATE_OR_DELETE_ID: { path: '/updateEmployeeID', id: 38 },
  CREATE_USER_ID: { path: '/createEmployeeUserId', id: 39 },
  USER_MAPPING: { path: '/userMapping', id: 40 },
  ROLES_DASHBOARD: { path: '/roles', id: 41 },
  NOTIFICATION_TEMPLATE: { path: '/notificationTemplate', id: 42 },
  CREATE_NOTIFICATION_TEMPLATE: { path: '/createNotificationTemplate', id: 43 },
  ASSIGN_UNASSIGN_PERMISSIONS: { path: '/assignedPermissions', id: 44 },
  ACTIONS: { path: '/actions', id: 45 },
  ITEM_CATALOGUE_DASHBOARD: { path: '/itemCatalogue', id: 46 },
  MODULE_ACCESS: { path: '/moduleAccess', id: 47 },
  PASSWORD_RULES: { path: '/passwordRules', id: 48 },
  ORG_CHART_SETUP: { path: '/orgChartSetup', id: 49 },
  ORG_STRUCTURE: { path: '/orgStructure', id: 50 },
  SOURCE_BANK: { path: '/sourceBank', id: 51 },
  CREATE_SOURCE_BANK: { path: '/createSourceBank', id: 52 },
  CREATE_BANK_BRANCHES: { path: '/createBankBranches', id: 53 },
  BANK_BRANCHES: { path: '/bankBranches', id: 54 },
  CREATE_BANK: { path: '/createBank', id: 55 },
  BANK: { path: '/bank', id: 56 },
  CREATE_JOB_BAND: { path: '/createJobBand', id: 57 },
  JOB_BAND: { path: '/jobBand', id: 58 },
  CREATE_JOB_GRADE: { path: '/createJobGrade', id: 59 },
  JOB_GRADE: { path: '/jobGrade', id: 60 },
  PUBLISH_ANNOUNCEMENT: { path: '/publishAnnouncement', id: 61 },
  LETTERS: { path: '/letters', id: 62 },
  CREATE_LETTERS: { path: '/createLetters', id: 63 },
  GENERATE_LETTERS: { path: '/generateLetter', id: 64 },
  EXIT_CONTROLS: { path: '/exitControl', id: 65 },
  PROBATION_CONTROL: { path: '/probationControl', id: 66 },
  PROBATION_PERIOD_SETUP: { path: '/probationPeriodSetup', id: 67 },
  MY_TRANSACTION_SUMMARY: { path: '/myTransactionSummary', id: 123 },
  MANAGER_TRANSACTION_SUMMARY: { path: '/teamTransactionSummary', id: 124 },
  TRANSACTION_SUMMARY: { path: '/transactionSummary', id: 125 }, // 68 id created before for common  transaction summary route
  HOLIDAY_CALENDER: { path: '/holidayCalendar', id: 69 },
  HOLIDAY_CALENDER_MASTER: { path: '/holidayCalendarMaster', id: 70 },
  HOLIDAY_CALENDER_CONFIGURATION: {
    path: '/holidayCalendarConfiguration',
    id: 71
  },
  HIRE_PROCESS_VIEW: { path: '/hireProcessView', id: 72 },
  ACTIVE_INACTIVE: { path: '/activeInActive', id: 73 }, //change route to /lockUnlock
  RESET_PASSWORD_BY_ADMIN: { path: '/resetPasswordByAdmin', id: 74 },
  PENDING_APPROVALS: { path: '/pendingApprovals', id: 75 },
  INITIATE_ACTIONS: { path: '/initiateActions', id: 76 },
  CREATE_USER_CREDENTIALS: { path: '/createUserCredentials', id: 77 },
  MY_TEAM: { path: '/my-team', id: 78 },
  VIEW_EMPLOYEE_DETAILS: { path: '/employeeDetails', id: 79 },

  //UPLOAD RESULTS
  UPLOAD_RESULTS: { path: '/uploadResults', id: 80 },
  UPLOAD_EMPLOYEES: { path: '/uploadEmployees', id: 81 },
  UPLOAD_DEPARTMENT: { path: '/uploadDepartment', id: 82 },
  UPLOAD_LOCATION: { path: '/uploadLocation', id: 83 },
  UPLOAD_DESIGNATION: { path: '/uploadDesignation', id: 84 },
  UPLOAD_NATIONAL_ID: { path: '/uploadNationalID', id: 85 },
  UPLOAD_ADDRESS: { path: '/uploadAddress', id: 86 },
  UPLOAD_PHONE: { path: '/uploadPhone', id: 87 },
  UPLOAD_EMAIL_ID: { path: '/uploadEmailID', id: 88 },
  UPLOAD_EMERGENCY_CONTACT: { path: '/uploadEmergencyContact', id: 89 },
  UPLOAD_DEPENDANTS: { path: '/uploadDependants', id: 90 },
  UPLOAD_BENEFICIARY: { path: '/uploadBeneficiary', id: 91 },
  UPLOAD_WORK_EXPERIENCE: { path: '/uploadWorkExperience', id: 92 },
  UPLOAD_DOCUMENT: { path: '/uploadDocument', id: 93 },
  UPLOAD_CERTIFICATE: { path: '/uploadCertificate', id: 94 },
  UPLOAD_LICENSE: { path: '/uploadLicense', id: 95 },
  UPLOAD_JOB_GRADE: { path: '/uploadJobGrade', id: 96 },
  UPLOAD_JOB_BAND: { path: '/uploadJobBands', id: 97 },
  UPLOAD_COMPANY_POLICY: { path: '/uploadCompanyPolicy', id: 98 },

  EMPLOYEE_INFO_HISTORY: { path: '/employeeInfoHistory', id: 99 },
  EDIT_BIOGRAPHICAL_DETAILS: { path: '/editBiographicalDetails', id: 100 },
  //DOWNLOAD REPORTS
  DOWNLOAD_RESULTS: { path: '/downloadResults', id: 101 },
  NATIONAL_ID_REPORT: { path: '/nationalIDReport', id: 102 },
  EMPLOYEE_DEPENDANT_OR_BENEFICIARY_DETAILS: {
    path: '/employeeDependantOrBeneficiaryDetails',
    id: 103
  },
  EMERGENCY_CONTACT_REPORT: { path: '/emergencyContactReport', id: 104 },
  EMPLOYEE_DETAILS_REPORT: { path: '/employeeDetailsReport', id: 105 },
  DEPARTMENT_REPORT: { path: '/departmentReport', id: 106 },
  BANK_TRANSFER_REPORT: { path: '/bankTransferReport', id: 251 },
  CASH_PAYMENT_REPORT: { path: '/cashPaymentReport', id: 252 },
  MONTHLY_PT_REPORT: { path: '/monthlyPTReport', id: 253 },
  MONTHLY_ESI_REPORT: { path: '/monthlyESIReport', id: 254 },
  EMPLOYEE_CERTIFICATE_OR_LICENSE_REPORTS: {
    path: '/employeeCertificateOrLicenseReports',
    id: 107
  },
  EMPLOYEE_ROLES_REPORT: { path: '/employeeRolesReport', id: 108 },
  EMPLOYEE_MISSING_INFO_REPORT: { path: '/employeeMissingInfoReport', id: 109 },
  EMPLOYEE_HIRE_SEPARATION: { path: '/employeeHireSeparation', id: 110 },
  MANAGER_CHANGE_UPLOAD: { path: '/managerChangeUpload', id: 111 },
  EMPLOYEE_PRIOR_WORK_EXPERIENCE_REPORT: {
    path: '/employeePriorWorkExperienceReport',
    id: 112
  },
  EMPLOYEE_COMPENSATION_REPORT: {
    path: '/employeeCompensationReport',
    id: 246
  },
  EMPLOYEE_BLOOD_GROUP: { path: '/employeeBloodGroup', id: 113 },

  PROBATION_CONFIRMATION: { path: '/probationConfirmation', id: 114 },
  EMPLOYEE_TIMELINE: { path: '/employeeTimeline', id: 115 },
  SUBMIT_RESIGNATION: { path: '/resignationDetails', id: 116 }, // rename route to to /submit Resignation
  MY_PEERS: { path: '/myPeers', id: 117 },
  MY_PROFILE: { path: '/myProfile', id: 118 },
  CREATE_MATERNITY_LEAVE_POLICY: {
    path: '/createMaternityLeavePolicy',
    id: 155
  },
  UPLOAD_LEAVE_BALANCES: { path: '/uploadLeaveBalances', id: 203 },
  ACCRUAL_POLICY: { path: '/accrualPolicy', id: 152 },
  LEAVE_CONTROL: { path: '/leaveControl', id: 153 },
  LEAVE_PROGRAM: { path: '/leaveProgram', id: 154 },
  CREATE_LEAVE_ACCRUAL_POLICY: { path: '/createLeaveAccrualPolicy', id: 133 },
  APPLY_PROXY_LEAVE_BY_ADMIN: { path: '/applyProxyLeaveByAdmin', id: 156 },
  ADMIN_LEAVE_HISTORY: { path: '/adminLeaveHistory', id: 157 },
  ADMIN_LEAVE_BALANCE_VIEW: { path: '/adminLeaveBalanceView', id: 158 },
  DEFINE_SHIFTS: { path: '/defineShifts', id: 159 },
  ADMIN_COMP_OFF_SUMMARY_VIEW: { path: '/adminCompOffSummaryView', id: 163 },
  VIEW_SHIFTS: { path: '/viewShifts', id: 164 },
  DEFINE_PATTERN: { path: '/definePattern', id: 165 },
  VIEW_PATTERN: { path: '/viewPatterns', id: 166 },
  UPLOAD_PATTERN: { path: '/uploadPattern', id: 167 },
  UPLOAD_LOPS: { path: '/uploadLOPs', id: 255 },
  UPLOAD_ONE_TIME_EARNING_DEDUCTIONS: {
    path: '/uploadOneTimeEarningDeductions',
    id: 256
  },
  UPLOAD_PFPTLWFESI: { path: '/uploadPFPTLWFESI', id: 257 },
  LEAVE_BALANCE_REPORT: { path: '/leaveBalanceReport', id: 168 },
  PENDING_LEAVE_REQUESTS_REPORT: {
    path: '/pendingLeaveRequestsReport',
    id: 169
  },
  LEAVE_PROGRAM_DETAILS_REPORT: { path: '/leaveProgramDetailsReport', id: 170 },
  LEAVE_ACCRUAL: { path: '/leaveAccrual', id: 171 },
  COMP_OFF_REPORT: { path: '/compOffReport', id: 172 },
  UPDATE_ENCASHMENT_STATUS: { path: '/updateEncashmentStatus', id: 173 },
  EMPLOYEE_ENCASHMENT_REPORT: { path: '/employeeEncashmentReport', id: 174 },
  UPLOAD_TDS: { path: '/uploadTDS', id: 258 },
  //components are not yet defined
  MY_LEAVES: { path: '/myLeaves', id: 175 },
  LEAVE_HISTORY: { path: '/leaveHistory', id: 176 },
  MY_COMPENSATION: { path: '/myCompensation', id: 225 },

  APPLY_LEAVE: { path: '/applyLeave', id: 179 },
  COMP_OFF_ELIGIBILITY: { path: '/compOffEligibility', id: 180 },

  //routes without permissions
  DASHBOARD: { path: '/dashboard', id: null },
  LOGIN: { path: '/login', id: null },
  ORGANIZATION_COMPONENT: { path: '/organization', id: null },
  RESET_PASSWORD: { path: '/resetPassword', id: null },
  FORGOT_PASSWORD: { path: '/forgotPassword', id: null },
  PRIVACY_POLICY: { path: '/privacyPolicy', id: null },
  SCHEDULE_A_DEMO: { path: '/schedule', id: null },
  ABOUT: { path: '/about', id: null },
  CAREERS: { path: '/careers', id: null },
  PRICING: { path: '/pricing', id: null },
  HOME: { path: '/', id: null },

  LEAVE_TYPES: { path: '/leaveTypes', id: 126 },
  CREATE_LEAVE_TYPES: { path: '/createLeaveTypes', id: 127 },
  LEAVE_ACCUMULATION: { path: '/leaveAccumulation', id: 129 },
  CREATE_LEAVE_ACCUMULATION: { path: '/createLeaveAccumulation', id: 130 },
  LEAVE_ENCASHMENT: { path: '/leaveEncashment', id: 135 },
  CREATE_LEAVE_ENCASHMENT: { path: '/createLeaveEncashment', id: 136 },
  LEAVE_POLICY_DEFINITION: { path: '/leavePolicyDefinition', id: 139 },
  CREATE_LEAVE_POLICY_DEFINITION: {
    path: '/createLeavePolicyDefinition',
    id: 140
  },
  LEAVE_COMP_OFF: { path: '/leaveCompOff', id: 146 },
  CREATE_LEAVE_COMP_OFF: { path: '/createLeaveCompOff', id: 147 },
  LEAVE_PROGRAM_DASHBOARD: { path: '/leaveProgram', id: 142 },
  CREATE_LEAVE_PROGRAM: { path: '/createLeaveProgram', id: 143 },
  ASSIGN_LEAVE_PROGRAM: { path: '/assignLeaveProgram', id: 149 },
  TEAM_LEAVE_CALENDAR: { path: '/teamLeaveCalendar', id: 160 },
  CANCEL_OR_ADJUST_LEAVE: { path: '/cancelOrAdjustLeave', id: 177 },
  LEAVE_BALANCE_DETAILS: { path: '/leaveBalanceDetails', id: 178 },
  PENDING_LEAVE_APPROVALS: { path: '/pendingLeaveApprovals', id: 181 },
  MANAGER_LEAVE_HISTORY: { path: '/managerLeaveHistory', id: 182 },
  TEAM_LEAVE_BALANCE_DETAILS: { path: '/teamLeaveBalanceDetails', id: 183 },
  TEAM_LEAVE_BALANCE: { path: '/teamLeaveBalance', id: 184 },
  APPLY_PROXY_LEAVE: { path: '/applyProxyLeave', id: 185 },
  APPROVE_OR_REJECT_LEAVE_REQUEST: {
    path: '/approveOrRejectLeaveRequest',
    id: 186
  },
  MANAGER_TEAM_LEAVE_CALENDAR: { path: '/managerTeamLeaveCalendar', id: 187 },
  LEAVE_ADJUSTMENT_REQUESTS: {
    path: '/leaveAdjustmentRequest',
    id: 188
  },
  TEAM_COMP_OFF_SUMMARY: {
    path: '/teamCompOffSummary',
    id: 189
  },
  PROCESS_RESULTS: { path: '/processResults', id: 161 },
  PROCESS_RESULT_DETAILS: { path: '/processResultDetails', id: 162 },

  //PAY ROLL
  CREATE_EARNING_DEDUCTION_CODES: {
    path: '/createEarningDeductionCodes',
    id: 204
  },
  EARNING_DEDUCTION_CODES: {
    path: '/earningDeductionCode',
    id: 205
  },
  DEFINE_COMPENSATION_STRUCTURE: {
    path: '/defineCompensationStructure',
    id: 206
  },
  SEARCH_COMPENSATION_STRUCTURE: {
    path: '/searchCompensationStructure',
    id: 207
  },
  CREATE_PAY_CALENDAR: {
    path: '/createPayCalendar',
    id: 208
  },
  PAY_CALENDAR: {
    path: '/payCalendar',
    id: 209
  },
  ASSIGN_PAY_CALENDAR: {
    path: '/assignPayCalendar',
    id: 210
  },
  EMPLOYEE_PAYMENT_OPTIONS: {
    path: '/employeePaymentOptions',
    id: 211
  },
  CREATE_EMPLOYEE_TAX_DATA: { path: '/createEmployeeTaxData', id: 212 },
  VIEW_EMPLOYEE_TAX_DATA: { path: '/employeeTaxData', id: 213 },
  ONE_TIME_DEDUCTION_EARNING: {
    path: '/createOneTimeEarningDeduction',
    id: 214
  },
  LOAN_MANAGEMENT_SETUP: {
    path: '/loanManagementSetup',
    id: 235
  },
  PAYROLL_CONTROLS: {
    path: '/payrollControls',
    id: 236
  },
  APPLY_PROXY_LOAN_BY_ADMIN: { path: '/applyProxyLoanByAdmin', id: 239 },
  SEARCH_SUB_SECTION: {
    path: '/subSection',
    id: 237
  },
  CREATE_SUB_SECTION: {
    path: '/createSubSection',
    id: 238
  },
  INVESTMENT_DECLARATION: { path: '/investmentDeclaration', id: 240 },
  POI: { path: '/poi', id: 241 },
  SALARY_REVISION: { path: '/salaryRevision', id: 240 },
  VIEW_COMPENSATION_HISTORY: {
    path: '/viewCompensationHistory',
    id: 245
  },
  SEARCH_GENERAL_DEDUCTIONS: { path: '/generalDeductions', id: 215 },
  CREATE_GENERAL_DEDUCTIONS: { path: '/createGeneralDeductions', id: 216 },
  CREATE_PT_SLAB: { path: '/definePTSlab', id: 217 },
  ASSIGN_PAY_GROUP: { path: '/asignPayGroup', id: 218 },
  CREATE_LWF_SLAB: { path: '/defineLWFSlab', id: 219 },
  PAY_GROUP_DASHBOARD: { path: '/payGroup', id: 220 },
  CREATE_PAY_GROUP: { path: '/createPayGroup', id: 221 },
  RUN_PROCESS: { path: '/runProcess', id: 222 },
  ADD_EMP_COMP: { path: '/addEmployeeCompensation', id: 234 },
  PENDING_LOAN_APPROVALS: { path: '/pendingLoanApprovals', id: 228 },
  APPROVE_OR_REJECT_LOAN_REQUEST: {
    path: '/approveOrRejectLoanRequest',
    id: 232
  },
  TEAM_COMPENSATION: { path: '/teamCompensation', id: 230 },
  SEARCH_IT_SECTION: { path: '/searchITSection', id: 259 },
  CREATE_IT_SECTION: { path: '/createITSection', id: 260 },
  INCOME_TAX_SLABS: { path: '/incomeTaxSlabs', id: 261 },
  CREATE_INCOME_TAX_SLABS: { path: '/createIncomeTaxSlabs', id: 262 },
  SECTION_80G: {
    path: '/section80G',
    id: 242
  },
  PAYSLIP_TEMPLATES: { path: '/payslipTemplates', id: 263 },
  INVESTMENT_DECLARATION_STATUS: {
    path: '/investmentDeclarationStatus',
    id: 276
  },
  EMPLOYEE_INVESTMENT_DECLARATION: {
    path: '/employeeInvestmentDeclaration',
    id: 277
  },
  MY_COMPENSATION_TEMPLATE: { path: '/myCompensationTemplate', id: 225 },
  MY_PAYSLIPS: { path: '/myPayslips', id: 225 },
  PAYROLL_DASHBOARD: { path: '/payrollDashboard', id: 247 },
  PRE_PAYROLL_SALARY_SHEET: {
    path: '/prePayrollSalarySheet',
    id: 264
  },
  CONFIRMATION_SALARY_SHEET: {
    path: '/confirmationSalarySheet',
    id: 265
  },
  EMPLOYEE_SALARY__YTD_REPORT: {
    path: '/employeeYTDSalaryReport',
    id: 248
  },
  ADM_SALARY_YTD_REPORT: {
    path: '/admYTDSalaryReport',
    id: 249
  },
  CALCULATION_SALARY_SHEET: {
    path: '/calculationSalarySheet',
    id: 266
  },
  RELEASE_PAYSLIP: { path: '/releasePayslip', id: 267 },
  SALARY_VARIANCE_REPORT: {
    path: '/salaryVarianceReport',
    id: 268
  },
  CREATE_ESIC_SLAB: { path: '/defineESICSlab', id: 269 },
  FULL_AND_FINAL_SETTLEMENT_SETUP: {
    path: '/fullAndFinalSettlementSetup',
    id: 270
  },
  FULL_AND_FINAL_SETTLEMENT: {
    path: '/fullAndFinalSettlement',
    id: 271
  },
  EMPLOYEE_FULL_AND_FINAL_SETTLEMENT2: {
    path: '/employeeFullAndFinalSettlement2',
    id: 272
  },
  FORM_V_PT_RETURNS: {
    path: '/formVPTReturns',
    id: 273
  },
  GRAPHICAL_VIEW_PAYSLIP: { path: '/graphicalViewPayslip', id: 274 },
  WAGE_REGISTER: { path: '/wageRegister', id: 275 },
  OPEN_CLOSE_IT_WINDOW: {
    path: '/openCloseITWindow',
    id: 250
  },
  PREVIOUS_ORG_COMPENSATION: {
    path: '/previousOrgCompensation',
    id: 278
  },
  ADD_PREVIOUS_EMPLOYMENT_DETAILS: {
    path: '/addPreviousEmploymentDetails',
    id: 279
  },
  CREATE_CALENDAR: { path: '/createCalendar', id: 285 },
  SEARCH_CALENDAR: { path: '/calendar', id: 284 },
  POI_STATUS: { path: '/poiStatus', id: 263 }
};

exports.FUNCTIONALITY_PERMISSION_CONSTANTS = {
  VIEW_WORKFORCE_MANAGEMENT: { id: 119 },
  VIEW_LEAVE_MANAGEMENT: { id: 120 },
  VIEW_UPLOADS: { id: 121 },
  VIEW_REPORTS: { id: 122 },
  VIEW_TRANSACTION_SUMMARY_BY_ADMIN: { id: 123 },
  VIEW_TRANSACTION_SUMMARY_BY_MANAGER: { id: 124 },
  VIEW_TRANSACTION_SUMMARY_BY_EMPLOYEE: { id: 125 },
  VIEW_PROCESSES: { id: 223 },
  VIEW_PAYROLL: { id: 224 },

  //edit pages
  EDIT_CUSTOMER: { id: 3 },
  EDIT_COMPANY: { id: 6 },
  EDIT_DEPARTMENT: { id: 28 },
  EDIT_DESIGNATION: { id: 34 },
  EDIT_LOCATION: { id: 31 },

  EDIT_LEAVE_TYPES: { id: 128 },
  EDIT_LEAVE_ACCUMULATION: { id: 131 },
  EDIT_LEAVE_ENCASHMENT: { id: 137 },
  EDIT_LEAVE_POLICY_DEFINITION: { id: 141 },

  CREATE_PERMISSIONS: { id: 8 },
  ASSIGN_PERMISSIONS_BY_ROLE: { id: 9 },
  ASSIGN_PERMISSIONS_BY_USERID: { id: 10 },
  DELETE_ACTION_REASON: { id: 12 },
  SWITCH_TO_ADMIN: { id: 150 },
  MANAGER_ACCESS: { id: 151 },
  PENDING_APPROVALS_FOR_ADMIN: { id: 190 },
  PENDING_LEAVE_APPROVALS_FOR_ADMIN: { id: 191 },
  PENDING_LOAN_APPROVALS_FOR_ADMIN: { id: 233 },
  PROBATION_CONFIRMATION_FOR_ADMIN: { id: 192 },
  VIEW_BIRTHDAYS_FOR_EMPLOYEES: { id: 193 },
  VIEW_ANNOUNCEMENTS_FOR_EMPLOYEES: { id: 194 },
  VIEW_COMPANY_POLICY_FOR_EMPLOYEES: { id: 195 },
  VIEW_QUICK_ACTIONS_FOR_EMPLOYEES: { id: 196 },
  VIEW_HOLIDAY_DASHBOARD_FOR_EMPLOYEES: { id: 197 },
  MY_LEAVES_MODULE: { id: 198 },
  TEAM_LEAVES_MODULE: { id: 199 },
  MY_PROFILE_MODULE: { id: 200 },
  CUSTOM_REPORT: { id: 201 },
  MY_COMPENSATION_MODULE: { id: 226 },
  TEAM_SALARY_MODULE: { id: 227 },
  TEAM_COMPENSATION_MODULE: { id: 229 },
  VIEW_TIME_ATTENDANCE: { id: 280 }
};

exports.LEAVE_STATUS_COLORS = {
  APPLIED: { backgroundColor: '#E5F8FE', color: '#26C6F9' },
  APPROVED: { backgroundColor: '#DDF8CB', color: '#72E128' },
  REJECTED: { backgroundColor: '#FFE9E9', color: '#FF4D49' },
  TAKEN: { backgroundColor: '#ECEDFF', color: '#666CFF' },
  CANCELLED: { backgroundColor: '#FEE7BA', color: '#FDB528' },
  ADJUSTED: { backgroundColor: 'Adjusted color', color: 'Adjusted color' },
  CLOSED: { backgroundColor: '#EEFBE5', color: '#22B14C' },
  RESTRICTED: { backgroundColor: '#FAF7E4', color: '#655714' }
};

exports.LEAVE_HISTORY_COLORS = {
  APPLIED: { backgroundColor: 'rgb(229 248 254)', color: 'rgb(38 198 249 )' },
  APPROVED: { backgroundColor: 'rgb(221 248 203)', color: 'rgb(114 255 40)' },
  REJECTED: { backgroundColor: 'rgb(255 233 233)', color: 'rgb(255 77 73)' },
  TAKEN: { backgroundColor: 'rgb(236 237 255)', color: 'rgb(102 108 255)' },
  'APPLIED CANCELLATION': {
    backgroundColor: 'rgb(254 231 186)',
    color: 'rgb(253 181 40)'
  },
  CANCELLED: { backgroundColor: 'rgb(254 231 186)', color: 'rgb(253 181 40)' },
  CLOSED: { backgroundColor: 'rgb(238 251 229)', color: 'rgb(34 177 76)' },
  ADJUSTED: { backgroundColor: 'rgb(297 231 211)', color: 'rgb(201 87 20)' },
  'APPLIED ADJUSTMENT': {
    backgroundColor: 'rgb(297 231 211)',
    color: 'rgb(201 87 20)'
  },
  'ADJUSTMENT REJECTED': {
    backgroundColor: 'rgb(255 233 233)',
    color: 'rgb(255 77 73)'
  }
};

exports.LEAVE_POLICY_TYPES = {
  LEAVE_POLICY: 'Leave Policy',
  ACCRUAL_POLICY: 'Accrual Policy',
  CARRY_FORWARD_AND_ACCUMULATION_POLICY:
    'Carry Forward and Accumulation Policy',
  MATERNITY_LEAVE_POLICY: 'Maternity Leave Policy',
  COMPENSATORY_LEAVE_POLICY: 'Compensatory Leave Policy'
};

//customReports
exports.CUSTOM_REPORTS = {
  REPORT4: { path: '/report1', rid: 4, id: 202, name: 'Custom Report 1' }
};
const payCalendarDateUnits = {
  PERVIOUS_MONTH: { value: '1', label: 'Previous Month' },
  CURRENT_MONTH: { value: '2', label: 'Current Month' },
  NEXT_MONTH: { value: '3', label: 'Next Month' }
};
exports.PAY_CALENDAR_UNITS = payCalendarDateUnits;
const payCalendarDateValues = [
  { label: '1st', value: '1' },
  { label: '2nd', value: '2' },
  { label: '3rd', value: '3' },
  { label: '4th', value: '4' },
  { label: '5th', value: '5' },
  { label: '6th', value: '6' },
  { label: '7th', value: '7' },
  { label: '8th', value: '8' },
  { label: '9th', value: '9' },
  { label: '10th', value: '10' },
  { label: '11th', value: '11' },
  { label: '12th', value: '12' },
  { label: '13th', value: '13' },
  { label: '14th', value: '14' },
  { label: '15th', value: '15' },
  { label: '16th', value: '16' },
  { label: '17th', value: '17' },
  { label: '18th', value: '18' },
  { label: '19th', value: '19' },
  { label: '20th', value: '20' },
  { label: '21st', value: '21' },
  { label: '22nd', value: '22' },
  { label: '23rd', value: '23' },
  { label: '24th', value: '24' },
  { label: '25th', value: '25' }
];
exports.PAY_CALENDAR_DATA_VALUES = payCalendarDateValues;

exports.PAY_CALENDAR_VALUES = {
  Monthly: {
    fromValues: payCalendarDateValues,
    fromUnits: [
      {
        label: payCalendarDateUnits.PERVIOUS_MONTH.label,
        value: payCalendarDateUnits.PERVIOUS_MONTH.value
      },
      {
        label: payCalendarDateUnits.CURRENT_MONTH.label,
        value: payCalendarDateUnits.CURRENT_MONTH.value
      }
    ],
    toValues: [...payCalendarDateValues, { label: 'End of', value: 'End of' }],

    toUnits: [
      {
        label: payCalendarDateUnits.PERVIOUS_MONTH.label,
        value: payCalendarDateUnits.PERVIOUS_MONTH.value
      },
      {
        label: payCalendarDateUnits.CURRENT_MONTH.label,
        value: payCalendarDateUnits.CURRENT_MONTH.value
      },
      {
        label: payCalendarDateUnits.NEXT_MONTH.label,
        value: payCalendarDateUnits.NEXT_MONTH.value
      }
    ],
    payDateValues: [
      ...payCalendarDateValues,
      { label: 'End of', value: 'End of' },
      { label: 'Last Working Day of', value: 'Last Working Day of' }
    ],
    payDateUnits: [
      {
        label: payCalendarDateUnits.PERVIOUS_MONTH.label,
        value: payCalendarDateUnits.PERVIOUS_MONTH.value
      },
      {
        label: payCalendarDateUnits.CURRENT_MONTH.label,
        value: payCalendarDateUnits.CURRENT_MONTH.value
      },
      {
        label: payCalendarDateUnits.NEXT_MONTH.label,
        value: payCalendarDateUnits.NEXT_MONTH.value
      }
    ]
  }
};

exports.APPROVER_LEVEL_ARRAY = [
  { label: '1st Level', value: 1 },
  { label: '2nd Level', value: 2 },
  { label: '3rd Level', value: 3 },
  { label: '4th Level', value: 4 },
  { label: '5th Level', value: 5 }
];

exports.CRITERIA_ARRAY_LIST = [
  {
    value: 'All Criteria',
    label: 'All Criteria'
  },
  {
    value: 'Based on Loan Amount',
    label: 'Based on Loan Amount'
  }
];

exports.APPROVER_ARRAY = [
  { value: 'Reporting Manager' },
  { value: 'L2' },
  { value: 'Role' },
  { value: 'Payroll Admin' }
];

exports.LEAVE_TYPE = {
  MATERNITY_LEAVE: 'MTL',
  COMP_OFF_LEAVE: 'CMPFL',
  RESTRICTED_HOLIDAY: 'RH',
  LOSS_OF_PAY: 'LOP'
};
